import { isPromoExperiment } from '../lib/experiment';

export const PROMOTION_NAME = '1 Day Offer 2024';

export const PromoDetails = {
  enabled: () => isPromoExperiment(),
  sessionStorageKey: 'promoBannerSSKey',
  onlyForSteps: ['StepRegistration'],
  promoLocked: `You've locked in extra cash on your sale! Submit your item now and ship by 10/25 to qualify. T&Cs apply* `,
  terms: `*TERMS & CONDITIONS: This offer makes you eligible to receive a flat fee of 5% on your sale. To qualify, FedEx tracking must show the package was scanned by October 28, 2024, and the item must be sold on or before November 18, 2024 at 11:59pm ET. To qualify for this promotion, you must submit your item by October 27, 2024 using the submission form provided on this site. May not be combined with any other savings and may only be used once on 1 item only. The promotional fee discount may be applied on the initial payment for the sale of your item or it may appear as a second payment sent within 14 business days of completing the sale.`,
};
