import {
  Box,
  Icon,
  IconButton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  worthyShineTheme,
} from '@worthy-npm/worthy-common-ui-components';
import React, { useEffect } from 'react';
import _ from 'lodash';
import { Theme } from '@emotion/react';
import { PromoDetails } from '../../data/promotion';
import { useAppSelector } from '../../app/hooks';
import { selectWizard } from '../../slices/submitSlice';
import { EXPERIMENTS, isPromoExperiment, variantIsLoaded } from '../../lib/experiment';

const BannerContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'relative',
  backgroundImage: "url('images/promotion/mobile.jpg')",
  padding: theme.spacing(1.5, 3),
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    backgroundImage: "url('images/promotion/desktop.jpg')",
    padding: theme.spacing(1.5, 5),
  },
}));

function PromoBanner() {
  const [showBanner, setShowBanner] = React.useState(false);
  const { currentVertexId } = useAppSelector(selectWizard);
  const isMobile = useMediaQuery(worthyShineTheme.breakpoints.down('sm'));

  useEffect(() => {
    const BannerClosedOnSession = !!sessionStorage.getItem(PromoDetails.sessionStorageKey);
    const isCorrectLoaction =
      _.isEmpty(PromoDetails.onlyForSteps) ||
      PromoDetails.onlyForSteps.includes(currentVertexId || '');
    if (isCorrectLoaction) {
      if (PromoDetails.enabled() && !BannerClosedOnSession && isPromoExperiment()) {
        variantIsLoaded(EXPERIMENTS.oneDayPromotion, ['1']);
        setShowBanner(true);
      } else {
        variantIsLoaded(EXPERIMENTS.oneDayPromotion, ['0']);
      }
    }
  }, [currentVertexId]);

  const onClose = () => {
    sessionStorage.setItem(PromoDetails.sessionStorageKey, 'true');
    setShowBanner(false);
  };
  return showBanner ? (
    <BannerContainer>
      <Stack
        direction={{ md: 'row' }}
        alignItems={{ md: 'center' }}
        gap={1}
        justifyContent="center"
      >
        <Typography variant="h5" fontWeight="light" noWrap overflow="visible">
          This Week Only:
        </Typography>
        <Typography variant="h4" noWrap overflow="visible">
          All Success Fees Reduced to 5%
        </Typography>
        <Typography variant="body1" maxWidth={400} mr={{ md: '80px' }}>
          Get up to 70% off your success fee when you submit and ship by Monday.*
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: 'column-reverse', lg: 'row' }}
        position="absolute"
        right={0}
        gap={{ xs: 0, lg: 4 }}
        alignItems="center"
        top={{ xs: 0, lg: 14 }}
      >
        {!isMobile ? (
          <Box>
            <Typography variant="caption" color="inherit">
              T&Cs apply*
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        <Box>
          <IconButton onClick={onClose}>
            <Icon.CloseIcon />
          </IconButton>
        </Box>
      </Stack>
    </BannerContainer>
  ) : (
    <></>
  );
}

export default PromoBanner;
